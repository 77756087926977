import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSetting() {
  // Use toast
  const toast = useToast()
  const fetchSetting = async () => {
    try {
      const response = await store.dispatch('app-home-setting/fetchSetting');
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetch setting',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const updateSetting = async (content) => {
    try {
      const response = await store.dispatch('app-home-setting/updateSetting', content);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error update setting',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const uploadImage = async (images) => {
    try {
      const response = await store.dispatch('app-home-setting/uploadImage', images);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error upload image',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    toast,
    fetchSetting,
    updateSetting,
    uploadImage,
  }
}
